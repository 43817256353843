import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, Card, Col, Divider, Row, Select, Tooltip } from 'antd'
import MailOutlined from "@ant-design/icons/lib/icons/MailOutlined";
import { Formik } from 'formik'
import { Form, Input, Checkbox, DatePicker } from 'formik-antd'
import * as Yup from 'yup'
import moment from 'moment'
import 'moment/locale/es-mx'
import { showAuthLoader, userSignUp, resetLoginInit, changeRegisterStateSuccess } from 'appRedux/actions/Auth'
import { getAllPhysicalDisability } from "appRedux/actions/BasicData";
import { getAllCountries, getCitiesByStateId, getStatesByCountryId } from 'appRedux/actions/Country'
import { getMessageValidate, getMessageByString, getMessageStringLengthValidate } from 'util/Messages'
import IntlMessages from 'util/IntlMessages'
import { CHECK_NUMBERS_POSITIVE_INTEGER, CHECK_MOBILE_PHONE_COLOMBIA } from 'constants/RegularExpressionsValidation'
import { LIST_ENUM_GENDER, LIST_ENUM_SCOLARITY, LIST_ENUM_STUDYING, LIST_ENUM_WORKING, LIST_ENUM_MIGRATION_STATE } from 'constants/ListEnum'
import CircularProgress from 'components/CircularProgress/index'
import PrivacyPolicies from './User/PrivacyPolicies'
import ContactMeans from './User/ContactMeans'
import logo from 'assets/images/logo.png'

const { Option } = Select
const FormItem = Form.Item

const SignUp = props => {

  const dispatch = useDispatch()
  const history = useHistory()

  const { loader, register_success, authUser, user } = useSelector(({ auth }) => auth)
  const { locale } = useSelector(({ settings }) => settings)
  const user_r = useSelector(({ user }) => user)
  const { physicalDisability } = useSelector(({ basicData }) => basicData)
  const { countriesList, listStatesByCountryId, listCitiesByStateId } = useSelector(({ country }) => country);

  const [privacyPolices, setPrivacyPolicy] = useState(false)
  const [termsCheck, setTermsCheck] = useState(false)
  const [selectedWorking, setSelectedWorking] = useState([]);
  const [selectedStuding, setSelectedStuding] = useState([]);
  const [selectedDisabilities, setSelectedDisabilities] = useState([]);
  const [stateAddressDisabled, setStateAddressDisabled] = useState(true);
  const [cityAddressDisabled, setCityAddressDisabled] = useState(true);

  const listAddressStatesByCountryId = listStatesByCountryId.address;
  const listtAddressCitiesByStateId = listCitiesByStateId.address;

  useEffect(() => {
    if (selectedWorking.includes("none") && selectedWorking.length > 1) {
      handleClearWorking()
    }
    if (selectedStuding.includes("none") && selectedStuding.length > 1) {
      handleClearStuding()
    }
    if (selectedDisabilities.includes("none") && selectedDisabilities.length > 1) {
      handleClearDisabilities()
    }
  }, [selectedWorking, selectedStuding, selectedDisabilities])

  useEffect(() => {
    dispatch(getAllPhysicalDisability());
    dispatch(getAllCountries());
  }, [dispatch])

  useEffect(() => {
    if (register_success) {
      dispatch(changeRegisterStateSuccess(false))
      if (
        user_r.user.email_verification_info &&
        user_r.user.email_verification_info.link_registration_completion
      ) {
        let domain = new URL(
          user_r.user.email_verification_info.link_registration_completion
        )
        history.push(domain.pathname)
      } else history.push('/signin')
    }
  }, [dispatch, history, register_success, user_r.user.email_verification_info])

  useEffect(() => {
    if (authUser !== null) {
      history.push('/')
    }
  }, [authUser, history])

  /*Funciones para limpiar los campos de current working, y current studing y 
physical disabilities cuando el usuario selecciona la opción de "ninguna"*/
  const handleChangeWorking = (value) => {
    setSelectedWorking(value);
  }
  const handleClearWorking = () => {
    setSelectedWorking(["none"]);
  };
  const handleChangeStuding = (value) => {
    setSelectedStuding(value);
  }
  const handleClearStuding = () => {
    setSelectedStuding(["none"]);
  };
  const handleChangeDisabilities = (value) => {
    setSelectedDisabilities(value);
  }
  const handleClearDisabilities = () => {
    setSelectedDisabilities(["none"]);
  };

  const backToSignIn = () => {
    dispatch(resetLoginInit())
    history.push('/signIn')
  }
  const logoStyle = {
    marginBottom: "2%",
    display: 'flex',
    justifyContent: "center",
  }

  const countryCode = (
    <FormItem name='countryCode' noStyle>
      <Select defaultValue='+57' name='countryCode' style={{ width: 70 }}>
        <Option value='+57'>+57</Option>
        <Option value='+58'>+58</Option>
        <Option value='+593'>+593</Option>
        <Option value='+591'>+591</Option>
        <Option value='+54'>+54</Option>
        <Option value='+51'>+51</Option>
        <Option value='+1'>+1</Option>
        <Option value='+598'>+598</Option>
        <Option value='+52'>+52</Option>
        <Option value='+55'>+55</Option>
        <Option value='+34'>+34</Option>
        <Option value='+507'>+507</Option>
      </Select>
    </FormItem>
  )
  const onFinish = values => {
    let availability_activities = []
    let camposRegister = {}
    let none = ""
    if (user) {
      camposRegister.name = values.name
      camposRegister.lastName = values.lastName
      camposRegister.cellphone = values.cellphone
      camposRegister.countryCode = values.countryCode
      camposRegister.email = values.email
      camposRegister.password = values.password
      camposRegister.password_confirmation = values.password_confirmation
      camposRegister.migration_state = values.migration_state
      camposRegister.birth_date = values.birth_date
      camposRegister.gender = values.gender
      camposRegister.physical_disabilities = selectedDisabilities[0] === "none" ? [] : selectedDisabilities
      camposRegister.scolarity = values.scolarity
      camposRegister.id_address_country = values.id_address_country
      camposRegister.id_address_city = values.id_address_city
      camposRegister.availability_activities = availability_activities
      camposRegister.terms = values.terms
      camposRegister.currentUser = user
    } else {
      camposRegister = user;
    }
    selectedStuding.forEach((studying) => {
      studying === "none" && (none = studying)
      availability_activities.push(studying)
    })
    selectedWorking.forEach((working) => {
      none !== working && (availability_activities.push(working))
    })
    availability_activities.length > 1 && availability_activities.includes("none") && (
      availability_activities.map((activity, index, object) => (
        activity === "none" && object.splice(index, 1)
      ))
    )
    camposRegister.locale = locale
    dispatch(showAuthLoader())
    dispatch(userSignUp(camposRegister))
  }

  const rulesFieldsFormSignUp = {
    name: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, 'app.userAuth.name'), 'valid.field.required'))
      .max(255, (getMessageStringLengthValidate(locale, getMessageByString(locale, "app.userAuth.name"), 255, "valid.field.maxlength"))),
    lastName: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, 'app.userAuth.lastName'), 'valid.field.required'))
      .max(255, (getMessageStringLengthValidate(locale, getMessageByString(locale, "app.userAuth.lastName"), 255, "valid.field.maxlength"))),
    cellphone: Yup.string()
      .nullable()
      .matches(CHECK_NUMBERS_POSITIVE_INTEGER, getMessageValidate(locale, getMessageByString(locale, 'app.userAuth.cellphone'), "valid.field.number.positive.integer"))
      .required(getMessageValidate(locale, getMessageByString(locale, 'app.userAuth.cellphone'), 'valid.field.required'))
      .max(10, (getMessageStringLengthValidate(locale, getMessageByString(locale, "app.userAuth.cellphone"), 10, "valid.field.maxlength")))
      .min(10, (getMessageStringLengthValidate(locale, getMessageByString(locale, "app.userAuth.cellphone"), 10, "valid.field.minlength")))
      .matches(CHECK_MOBILE_PHONE_COLOMBIA, getMessageValidate(locale, getMessageByString(locale, "app.userAuth.cellphone"), "valid.field.mobilephone.colombia")),
    countryCode: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, 'app.userAuth.countryCode'), 'valid.field.required')),
    email: Yup.string()
      .nullable()
      .email(getMessageValidate(locale, getMessageByString(locale, 'app.userAuth.mail'), 'valid.field.mail'))
      .required(getMessageValidate(locale, getMessageByString(locale, 'app.userAuth.mail'), 'valid.field.required'))
      .max(100, (getMessageStringLengthValidate(locale, getMessageByString(locale, "app.userAuth.mail"), 100, "valid.field.maxlength"))),
    password: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, 'app.userAuth.password'), 'valid.field.required'))
      .min(6, getMessageStringLengthValidate(locale, getMessageByString(locale, 'app.userAuth.password'), 6, 'valid.field.minlength'))
      .max(50, (getMessageStringLengthValidate(locale, getMessageByString(locale, "app.userAuth.mail"), 50, "valid.field.maxlength"))),
    password_confirmation: Yup.string()
      .nullable()
      .required(getMessageByString(locale, 'valid.field.required.password.confirmation'))
      .oneOf([Yup.ref('password'), null], getMessageByString(locale, 'valid.field.password.confirmation'))
      .max(50, (getMessageStringLengthValidate(locale, getMessageByString(locale, "app.userAuth.mail"), 50, "valid.field.maxlength"))),
    migration_state: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.migrationState"), "valid.field.required")),
    birth_date: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.birthDate"), "valid.field.required"))
      .test("Validate Date min", getMessageValidate(locale, getMessageByString(locale, "app.userAuth.birth_date"), "valid.field.age.type.1"),
        value => { return moment().subtract({ years: 17, months: 9 }) >= moment(value); }),
    gender: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.gender"), "valid.field.required")),
    physical_disabilities: Yup.array()
      .nullable()
      .of(Yup.string().required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.physicalDisabilities"), "valid.field.required")))
      .min(1, getMessageStringLengthValidate(locale, getMessageByString(locale, 'app.userAuth.physicalDisabilities'), 1, 'valid.field.minlength')),
    scolarity: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.scolarity"), "valid.field.required")),
    id_address_country: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.addressCountry"), "valid.field.required")),
    address_state: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.addressState"), "valid.field.required")),
    id_address_city: Yup.string()
      .nullable()
      .required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.addressCity"), "valid.field.required")),
    currently_working: Yup.array()
      .nullable()
      .of(Yup.string().required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.currentlyWorking"), "valid.field.required")))
      .min(1, getMessageStringLengthValidate(locale, getMessageByString(locale, 'app.userAuth.currentlyWorking'), 1, 'valid.field.minlength')),
    currently_studying: Yup.array()
      .nullable()
      .of(Yup.string().required(getMessageValidate(locale, getMessageByString(locale, "app.userAuth.currentlyStudying"), "valid.field.required")))
      .min(1, getMessageStringLengthValidate(locale, getMessageByString(locale, 'app.userAuth.currentlyStudying'), 1, 'valid.field.minlength')),
    terms: Yup.boolean()
      .nullable()
      .oneOf([true], getMessageByString(locale, 'app.userAuth.terms'))
  }
  if (user.uid) {
    delete rulesFieldsFormSignUp['password']
    delete rulesFieldsFormSignUp['password_confirmation']
  }
  const SignupSchema = Yup.object().shape(rulesFieldsFormSignUp)
  return (
    <section className='ant-layout'>
      <main className='ant-layout-content'>
        <div className='gx-main-content-wrapper'>
          <div className='gx-app-login-container' style={{ maxWidth: "70vw" }}>
            <div className='gx-app-login-main-content'>
              <div className='gx-app-logo-content' style={{ maxWidth: "35%" }}>
                <div style={logoStyle} className='gx-app-logo gx-mb-5'>
                  <img alt='Logo Agile Innova' src={logo} />
                </div>
                <div className='gx-app-logo-wid'>
                  <h1 style={{ color: "DimGrey" }} >
                    <IntlMessages id='app.userAuth.signUp.title' />
                  </h1>
                  <Divider></Divider>
                  <div style={{ textAlign: "justify", color: "DimGrey", marginBottom: '20px' }} >
                    <div dangerouslySetInnerHTML={{
                      __html: getMessageByString(
                        locale,
                        'app.userAuth.signUp.description'
                      )
                    }} />
                  </div>
                  <span className="gx-contact-buttoms gx-mt-4">
                    <div className="gx-mb-2 gx-text-center" style={{ color: "#1978D2" }}>
                      <IntlMessages id="app.userAuth.orConnectWith" />
                    </div>
                    <ContactMeans />
                  </span>
                  <span className="gx-mb-3" style={{ color: "dimgrey", textAlign: "justify" }} >
                    <IntlMessages id="app.userAuth.technicalSupport" />
                  </span>
                  <ul className="gx-support-link gx-mt-2" style={{ textAlign: "center", marginLeft: "0", paddingLeft: "0" }}>
                    <li>
                      <small>
                        <Tooltip
                          placement="top"
                          title="contacto@agileinnova.org"
                          arrowPointAtCenter
                        >
                          <MailOutlined
                            onClick={() =>
                              window.open("mailto:contacto@agileinnova.org", "_self")
                            }
                          />
                        </Tooltip>
                      </small>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='gx-app-login-content' style={{ width: "65%" }}>
                <Formik
                  onSubmit={onFinish}
                  initialValues={{
                    name: user.name,
                    lastName: user.lastname,
                    cellphone: user.phoneNumber,
                    countryCode: '+57',
                    email: user.email,
                    password: '',
                    password_confirmation: '',
                    migration_state: '',
                    birth_date: '',
                    gender: '',
                    physical_disabilities: [''],
                    scolarity: '',
                    id_address_country: '',
                    address_state: '',
                    id_address_city: '',
                    currently_studying: [''],
                    currently_working: [''],
                    terms: false
                  }}
                  validationSchema={SignupSchema}
                >{({ errors, touched, setFieldTouched, setFieldValue, values }) => (
                  <div className="gx-mt-3">
                    <Form name='basic' className='gx-signin-form gx-form-row0'>
                      <Card className="gx-my-4">
                        <strong> Nota: </strong> Los campos marcados con asterisco (<span className="gx-text-danger">*</span>) son obligatorios.
                      </Card>
                      <Row>
                        <legend><h3><IntlMessages id='app.userAuth.accountData' /></h3></legend>
                        <p className='gx-mb-4'>Diligenciar el nombre completo (nombres y apellidos), tal como aparecen en el documento
                          de identidad. Solo escribir en mayúscula la primera letra de cada palabra y escribir las
                          tildes respectivas, según corresponda con la ortografía de su nombre.</p>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="name"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.name' /></label>
                          <FormItem name='name'>
                            <Input
                              suffix={<span />}
                              name='name'
                              type='text'
                              fast={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="lastName"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.lastName' /></label>
                          <FormItem name='lastName'>
                            <Input
                              suffix={<span />}
                              name='lastName'
                              type='text'
                              fast={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="cellphone"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.cellphone' /></label>
                          <FormItem name='cellphone'>
                            <Input
                              suffix={<span />}
                              addonBefore={countryCode}
                              name='cellphone'
                              style={{ width: '100%' }}
                              fast={true}
                            />
                          </FormItem>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="email" title="email"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.mail' /></label>
                          <FormItem name='email'>
                            <Input
                              suffix={<span />}
                              name='email'
                              type='email'
                              disabled={user.uid ? true : false}
                              fast={true}
                            />
                          </FormItem>
                        </Col>
                        {user.uid ? null : (
                          <>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                              <label id="password"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.password' /></label>
                              <FormItem name='password'>
                                <Input
                                  suffix={<span />}
                                  type='password'
                                  name='password'
                                  fast={true}
                                />
                              </FormItem>
                            </Col>
                            <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                              <label id="password_confirmation"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.password_confirmation' /></label>
                              <FormItem name='password_confirmation'>
                                <Input
                                  suffix={<span />}
                                  type='password'
                                  name='password_confirmation'
                                  fast={true}
                                />
                              </FormItem>
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row>
                        <legend><h3><IntlMessages id='app.userAuth.basicData' /></h3></legend>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="migration_state"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.migrationState' /></label>
                          <Form.Item name='migration_state'>
                            <Select name="migration_state"
                              placeholder="Seleccione..."
                              onChange={(value) => {
                                setFieldValue('migration_state', value)
                              }}
                            >
                              {
                                LIST_ENUM_MIGRATION_STATE.length !== 0
                                && LIST_ENUM_MIGRATION_STATE.map((migration_state) =>
                                  <Option value={migration_state.type} key={migration_state.type}>{migration_state.value}</Option>
                                )
                              }
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="birth_date"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.birthDate' /></label>
                          <FormItem name='birth_date'>
                            <DatePicker
                              name="birth_date"
                              className="gx-w-100"
                              showToday={false}
                              fast={true}
                              onChange={(momentParams, value) => {
                                setFieldValue('birth_date', value)
                              }}
                            />
                          </FormItem>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="gender"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.gender' /></label>
                          <FormItem name='gender'>
                            <Select
                              name='gender'
                              placeholder='Seleccione...'
                              onChange={(value) => {
                                setFieldValue('gender', value)
                              }}
                            >
                              {
                                LIST_ENUM_GENDER.map(gender =>
                                  <Option value={gender.type} key={gender.type}>{gender.value}</Option>)
                              }
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="physical_disabilities"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.physicalDisabilities' /></label>
                          <FormItem name='physical_disabilities'>
                            <Select
                              value={selectedDisabilities}
                              name="physical_disabilities"
                              placeholder="Seleccione..."
                              mode="multiple"
                              optionFilterProp="children"
                              showArrow
                              onChange={(value) => {
                                if (values.physical_disabilities.includes("none") && values.physical_disabilities.length > 1) {
                                  setFieldValue('physical_disabilities', ["none"])
                                } else {
                                  handleChangeDisabilities(value)
                                  setFieldValue('physical_disabilities', value)
                                }
                              }}
                            >
                              {
                                physicalDisability.length !== 0
                                && physicalDisability.map((value) =>
                                  <Option value={value.id} key={value.id}>{value.disability_name}</Option>
                                )
                              }
                              <Option value="none" key="none">Ninguna</Option>
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="scolarity"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.scolarity' /></label>
                          <FormItem name='scolarity'>
                            <Select
                              name='scolarity'
                              placeholder='Seleccione...'
                              onChange={(value) => {
                                setFieldValue('scolarity', value)
                              }}
                            >
                              {
                                LIST_ENUM_SCOLARITY.length !== 0
                                && LIST_ENUM_SCOLARITY.map((scolarity) =>
                                  <Option value={scolarity.type} key={scolarity.type}>{scolarity.value}</Option>
                                )
                              }
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="id_address_country"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.addressCountry' /></label>
                          <FormItem name='id_address_country'>
                            <Select
                              name="id_address_country"
                              placeholder="Buscar para seleccionar"
                              showSearch
                              optionFilterProp="children"
                              onChange={(value) => {
                                setFieldValue('id_address_country', value)
                                setFieldValue('address_state', null)
                                setFieldValue('id_address_city', null)
                                setStateAddressDisabled(false)
                                setCityAddressDisabled(true)
                                dispatch(getStatesByCountryId(value, 'address'));
                              }}
                            >
                              {
                                countriesList.length !== 0
                                && countriesList.map((country) =>
                                  <Option value={country.id} key={country.id}> {country.country_name} </Option>)
                              }
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="address_state"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.addressState' /></label>
                          <FormItem name='address_state'>
                            <Select
                              name="address_state"
                              placeholder="Buscar para seleccionar"
                              showSearch
                              optionFilterProp="children"
                              onChange={(value) => {
                                setFieldValue('address_state', value)
                                setFieldValue('id_address_city', null)
                                setCityAddressDisabled(false)
                                dispatch(getCitiesByStateId(value, 'address'))
                              }}
                              disabled={stateAddressDisabled}
                            >
                              {
                                listAddressStatesByCountryId.length === 0 ? '' : listAddressStatesByCountryId.map((state) =>
                                  <Option value={state.id} key={state.id}> {state.state_name} </Option>)
                              }
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xl={12} lg={12} md={12} sm={24} xs={24}>
                          <label id="id_address_city"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.addressCity' /></label>
                          <FormItem name='id_address_city'>
                            <Select
                              name="id_address_city"
                              placeholder="Buscar para seleccionar"
                              showSearch
                              optionFilterProp="children"
                              onChange={(value) => {
                                setFieldValue('id_address_city', value)
                              }}
                              disabled={cityAddressDisabled}
                            >
                              {
                                listtAddressCitiesByStateId.length === 0 ? '' : listtAddressCitiesByStateId.map((city) =>
                                  <Option value={city.id} key={city.id}>{city.city_name}</Option>)
                              }
                            </Select>
                          </FormItem>
                        </Col>
                      </Row>
                      <Row>
                        <legend><h3><IntlMessages id='app.userAuth.availability' /></h3></legend>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <label id="currently_studying"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.currentlyStudying' /></label>
                          <FormItem name='currently_studying'>
                            <Select
                              value={selectedStuding}
                              mode="multiple"
                              optionFilterProp="children"
                              showArrow
                              name='currently_studying'
                              placeholder='Seleccione...'
                              onChange={(value) => {
                                if (values.currently_studying.includes("none") && values.currently_studying.length > 1) {
                                  setFieldValue('currently_studying', ["none"])
                                } else {
                                  handleChangeStuding(value)
                                  setFieldValue('currently_studying', value)
                                }
                              }}
                            >
                              {
                                LIST_ENUM_STUDYING.map(currently_studying =>
                                  <Option value={currently_studying.type} key={currently_studying.type}>{currently_studying.value}</Option>)
                              }
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <label id="currently_studying"><span className="gx-text-danger gx-mr-1">*</span><IntlMessages id='app.userAuth.currentlyWorking' /></label>
                          <FormItem name='currently_working'>
                            <Select
                              value={selectedWorking}
                              mode="multiple"
                              optionFilterProp="children"
                              showArrow
                              name='currently_working'
                              placeholder='Seleccione...'
                              onChange={(value) => {
                                if (values.currently_working.includes("none") && values.currently_working.length > 1) {
                                  setFieldValue('currently_working', ["none"])
                                } else {
                                  handleChangeWorking(value)
                                  setFieldValue('currently_working', value)
                                }
                              }}
                            >
                              {
                                LIST_ENUM_WORKING.map(working =>
                                  <Option value={working.type} key={working.type}>{working.value}</Option>)
                              }
                            </Select>
                          </FormItem>
                        </Col>
                        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                          <FormItem name='terms' valuePropName='checked'>
                            <Checkbox
                              name='terms'
                              checked={termsCheck}
                              onChange={e => {
                                setTermsCheck(e.target.checked)
                              }}
                            >
                              <IntlMessages id='appModule.iAccept' />{' '}
                              <span
                                className='gx-signup-form-forgot gx-link' onClick={e => {
                                  setTermsCheck(termsCheck); setPrivacyPolicy(true)
                                }}
                              >
                                <IntlMessages id='appModule.termAndCondition' />
                              </span>
                            </Checkbox>
                          </FormItem>
                        </Col>
                        <div className='ant-row ant-form-item'>
                          <div style={{ display: "flex", marginLeft: "auto", marginRight: "auto" }} className='ant-col'>
                            <Button
                              type='primary'
                              className='gx-mb-0'
                              htmlType='submit'
                              value="Registrar"
                            >
                              <IntlMessages id='app.userAuth.signUp' />
                            </Button>
                            <span style={{ alignSelf: "center" }}>
                              <IntlMessages id="app.userAuth.signIn.back" />
                              <Button type='link' onClick={() => backToSignIn()}>{' '}<IntlMessages id="app.userAuth.signIn.back2" /></Button>
                            </span>
                          </div>
                        </div>
                      </Row>
                    </Form>
                  </div>
                )}
                </Formik>
              </div>
              {loader && (
                <div className='gx-loader-view'>
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
          <PrivacyPolicies
            open={privacyPolices}
            setCloseModal={setPrivacyPolicy}
          />
        </div>
      </main>
    </section>
  )
}
export default SignUp